<template>
    <div class="page">
        <img class="lArrow" src="../../assets/image/lArrow@2x.png" alt="" srcset="">
        <div class="mapImg">
            <div v-if="item" v-for="(item, index) in list" :key="index" class="cityBox"
                :style="{ 'top': item.coord[0] + '%', 'left': item.coord[1] + '%' }">
                <div class="scatterBox" v-if="item">
                    <div v-if="item.ejjdNum" class="scatter aniMapIcon">
                        <span v-if="item.cityName" class="ejjdNum">{{ item.ejjdNum }}</span>
                        <img src="../../assets/image/sb@2x.png" style="width: 18px;" />
                    </div>
                    <div v-if="item.companyNum" class="scatter scatter0 aniMapIcon">
                        <span class="qyjdNum">{{ item.companyNum }}</span>
                        <img src="../../assets/image/cgq@2x.png" style="width: 18px;" />
                    </div>
                </div>
                <span class="cityName" v-if="item.cityName">{{ item.cityName }}</span>
            </div>
        </div>
        <div class="remarks">
            <div>
                <img src="../../assets/image/sb@2x.png" width="30px">
                <span class="tname">设备分布</span>
            </div>
            <div>
                <img src="../../assets/image/cgq@2x.png" width="30px">
                <span class="tname1">传感器分布</span>
            </div>
        </div>
        <div class="dz">
            <img class="dzimg" src="../../assets/image/dz@2x.png" alt="" srcset=""></img>
        </div>
    </div>
</template>
<script>
import { deviceMap } from '@/api/index.js'
export default {
    data() {
        return {
            // datas: [{ "cityCode": "140700", "cityName": "山西省", "companyNum": 5, "rate": 5, "ejjdNum": 0 }, { "cityCode": "140300", "cityName": "北京市", "companyNum": 1, "rate": 2, "ejjdNum": 0 }, { "cityCode": "140300", "cityName": "陕西省", "companyNum": 1, "rate": 2, "ejjdNum": 0 }, { "cityCode": "140300", "cityName": "河北省", "companyNum": 1, "rate": 2, "ejjdNum": 0 }],
            datas: [],
            list: [],
            shanxiAllDistrict: {
                '山西省': [44, 55],
				'北京市': [38, 62],
				'河北省': [42, 59],
				'陕西省': [53, 48],
				'山东省': [46, 64],
				'辽宁省': [0, 0],
				'吉林省': [0, 0],
				'黑龙江省': [0, 0],
				'江苏省': [0, 0],
				'浙江省': [0, 0],
				'安徽省': [0, 0],
				'福建省': [0, 0],
				'江西省': [0, 0],
				'山东省': [0, 0],
				'河南省': [0, 0],
				'湖北省': [0, 0],
				'湖南省': [0, 0],
				'广东省': [0, 0],
				'海南省': [0, 0],
				'四川省': [0, 0],
				'贵州省': [0, 0],
				'云南省': [0, 0],
				'陕西省': [0, 0],
				'甘肃省': [0, 0],
				'青海省': [0, 0],
				'台湾省': [0, 0],
				'天津市': [0, 0],
				'上海市': [0, 0],
				'重庆市': [0, 0],
				'香港特别行政区': [0, 0],
				'澳门特别行政区': [0, 0],
				'内蒙古自治区': [0, 0],
				'广西壮族自治区': [0, 0],
				'西藏自治区': [0, 0],
				'宁夏回族自治区': [0, 0],
				'新疆维吾尔自治区': [0, 0]
                // '北京市': [38, 62],
                // '河北省': [42, 59],
                // '陕西省': [53, 48]
            },
        }
    },
    created() {
        this.getdeviceMap()
    },
    methods: {
        getdeviceMap() {
            deviceMap({
                entId: '1598124821618962433'
            }).then(res => {
                res.map((item, index)=> {
                    this.datas.push({
                        cityName: item.province,
                        companyNum: item.deviceNum,
                        ejjdNum: item.cgqNum
                    })
                })
                this.setMapData(this.datas)

            })
        },
        setMapData(val) {
            this.list = this.calcData(val)
        },
        calcData(data) {
            let res = data.map(item => {

                if (item.cityName) {

                    return {
                        coord: this.shanxiAllDistrict[item.cityName],
                        ...item,
                    }
                }
            })
            return res
        }
    }
}
</script>
<style scoped>
.page {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.lArrow {
    width: 132px;
    height: 67px;
}

.mapImg {
    width: 796px;
    height: 633px;
    background: url('../../assets/image/map@2x.png') top center no-repeat;
    background-size: 100% 100%;
}

.dz {
    position: absolute;
    bottom: -80px;
}

.dzimg {
    width: 390px;
    height: 109px;
}


.cityName {
    font-size: 13px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 3px;
}

.cityBox {
    position: absolute;
    /* border: 1px solid #f00; */
}

.qyjdNum {
    font-size: 16px;
    font-family: DIN;
    font-weight: bold;
    color: #8BF3FF;
    background: linear-gradient(-36deg, #FFDD8B 0.146484375%, #FFFEFD 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.ejjdNum {
    font-size: 16px;
    font-family: DIN;
    font-weight: bold;
    color: #8BF3FF;
    background: linear-gradient(-36deg, #55A8FF 0%, #FFFEFD 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.scatter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 53px;
    /* border: 1px solid #00f; */
}

.scatter0 {
    margin-left: 5px;
}

.scatterBox {
    display: flex;
    align-items: center;
    height: 41px;
    margin-bottom: 2px;
}

.cityBox {
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid #f00; */
}

.aniMapIcon {
    animation: marquee 2s linear 0s infinite;
}

@keyframes marquee {
    0% {
        opacity: 1;
        transform: translateY(0)
    }

    50% {
        opacity: 0.7;
        transform: translateY(-10%)
    }

    100% {
        opacity: 1;
        transform: translateY(0)
    }
}

.remarks {
    position: absolute;
    bottom: -80px;
    left: 10px;
}

.remarks>div {
    display: flex;
    align-items: center;
    line-height: 60px;
}

.tname {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #7BE1FE;
    margin-left: 10px;
}

.tname1 {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #53FDD5;
    margin-left: 10px;
}
</style>