<template>
	<div class="page">
		<div class="titlebox">工单列表</div>
		<div class="tableList">
			<div class="title">
				<div>状态</div>
				<div>维护人员</div>
				<div>创建时间</div>
				<div>备注</div>
			</div>
			<div class="info">
				<vue-seamless-scroll :data="list" class="warp" :class-option="seamlessScrollOption">
					<ul>
						<li v-for="(item, index) in list" :key="index">
							<div class="list">
								<div :style="{ color: item.status ? '#F60B63' : '#1AD193' }">{{
                                    item.status ? '未处理' : '已处理'
                                }}</div>
								<div>{{ item.name }}</div>
								<div>{{ item.createTime }}</div>
								<div>{{ item.remark }}</div>
							</div>

						</li>
					</ul>
				</vue-seamless-scroll>

			</div>
		</div>
	</div>
</template>
<script>
	import {
		workOrderList
	} from '@/api/index.js'
	import vueSeamlessScroll from 'vue-seamless-scroll'
	export default {
		data() {
			return {
				list: []
			}
		},
		components: {
			vueSeamlessScroll
		},
		computed: {
			seamlessScrollOption() {
				return {
					step: 0.2, // 数值越大速度滚动越快
					limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
					hoverStop: false, // 是否开启鼠标悬停stop
					direction: 1, // 0向下 1向上 2向左 3向右
					openWatch: true, // 开启数据实时监控刷新dom
					singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
					singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
					waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
				};
			},
		},
		mounted() {
			this.getWorkOrder()
		},
		methods: {
			getWorkOrder() {
				workOrderList({
					entId: '1598124821618962433',
					from: 1
				}).then(res => {
					this.list = res.content
				})
			}
		}
	}
</script>
<style scoped>
	.page {
		margin-top: 20px;
	}

	.titlebox {
		width: 480px;
		height: 54px;
		background: url('../../assets/image/titlebox@2x.png') top center no-repeat;
		background-size: 100% 100%;
		display: flex;
		align-items: center;
		font-size: 20px;
		font-family: Alibaba PuHuiTi;
		font-weight: 500;
		color: #FFFFFF;
		padding-left: 20px;
		box-sizing: border-box;
	}

	.tableList {
		padding: 20px;
		box-sizing: border-box;
		border: 1px solid #1A2438;
	}

	.info {
		height: 100px;
		overflow: hidden;
	}

	.title {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 500;
		color: #A3C8FF;
	}

	.title>div {
		margin: 4px 2px;
		padding: 2px 5px;
	}

	.title>div:nth-child(1),
	.list>div:nth-child(1) {
		width: 15%;
	}

	.title>div:nth-child(2),
	.list>div:nth-child(2) {
		width: 15%;
	}

	.title>div:nth-child(3),
	.list>div:nth-child(3) {
		width: 40%;
	}

	.title>div:nth-child(4),
	.list>div:nth-child(4) {
		width: 20%;
	}

	.list {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #FFFFFF;
	}

	.list>div {
		background: #041635;
		margin: 2px;
		padding: 5px;
	}
</style>