import { get, post } from '../utils/request'

//工单列表
export function workOrderList(params) {
  return get('/api/workOrder/dp/list', params)
}

//最新数据
export function queryNowData(params) {
  return get('/api/dp/queryNowData', params)
}

//传感器数量
export function cgqCount(params) {
  return get('/api/dp/cgq/count', params)
}

//类型数量
export function cgqType(params) {
  return get('/api/dp/cgq/typecount', params)
}

//设备数量
export function deviceCount(params) {
  return get('/api/dp/device/count', params)
}

//标识注册、解析统计
export function statistics(params) {
  return get('/api/dp/statistics', params)
}

//设备类型数量
export function deviceType(params) {
  return get('/api/dp/type/count', params)
}

//设备类型占比
export function deviceratio(params) {
  return get('/api/dp/device/type/ratio', params)
}

//传感器类型占比
export function cgqratio(params) {
  return get('/api/dp/cgq/type/ratio', params)
}

//设备/传感器地图分布
export function deviceMap(params) {
  return get('/api/dp/deviceMap', params)
}

//仪表盘数据
export function currentData(params) {
  return get('/api/dp/currentData', params)
}

//统计数据
export function dayData(params) {
  return get('/api/dp/dayData', params)
}

//查询传感器
export function noPage(params) {
  return get('/api/dp/noPage', params)
}

//获取企业
export function information(params) {
  return get('/api/dp/information', params)
}

//获取企业
export function deviceList(params) {
  return get('/api/dp/device', params)
}