/* eslint-disable no-unused-vars,eqeqeq */
import axios from 'axios/index'
// axios.defaults.withCredentials = true // 让ajax携带cookie
import { getCookie } from './util' // 引用刚才我们创建的util.js文件，并使用getCookie方法
// 1.引入vue
import Vue from 'vue'
// 2.新创建一个vue实例
const nVue = new Vue()
// import {MessageBox, Message} from 'element-ui'
import store from '../store/store'
import router from '../router/router'
// create an axios instance
const service = axios.create({
  transformRequest: [function(data) {
    // 将数据转换为表单数据
    let ret = ''
    for (const it in data) {
      ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
    }
    // console.log('ret', ret)
    return ret
  }],
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    const token = getCookie('session') // 获取Cookie

    // config.data = JSON.stringify(config.data)

    config.headers = {

      'Content-Type': 'application/x-www-form-urlencoded' // 设置跨域头部

    }

    if (token) {
      config.params = { 'token': token } // 后台接收的参数，后面我们将说明后台如何接收
    }

    return config
  },
  error => {
    // do something with request error
    //  console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

  /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
  response => {
    // console.log(response);
    if (response.config.responseType == 'blob') {
      // console.log(response.headers.filename)
      downloadFile(response.data, response.headers.filename)
      return
    }
    const res = response.data
    var self = this
    // if the custom code is not 20000, it is judged as an error.
    // return Promise.resolve(response)
    return res
    // if (res.code == 1) {
     
    // } else if (res.code == 2) {
    //   nVue.$message({
    //     message: '请重新登录',
    //     type: 'warn',
    //     duration: 3 * 1000
    //   })
    //   store.commit('del_token')
    //   store.commit('del_name')
    //   store.commit('del_pass')
    //   store.commit('del_user')
    //   router.push({ path: '/comLogin' })
    // } else {
    //   /*  nVue.$message({
    //             message: res.info || 'Error',
    //             type: 'error',
    //             duration: 3 * 1000
    //         });*/

    //   // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    //   if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
    //     // to re-login
    //     /* MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
    //                 confirmButtonText: 'Re-Login',
    //                 cancelButtonText: 'Cancel',
    //                 type: 'warning'
    //             }).then(() => {
    //                 store.dispatch('user/resetToken').then(() => {
    //                     location.reload()
    //                 })
    //             })*/
    //   }
    //   return Promise.reject(new Error(res.info || 'Error'))
    // }
  },
  error => {
    var self = this
    console.log('err' + error)
    /*  nVue.$message({
            message: error.message,
            type: 'error',
            duration: 3 * 1000
        })*/
    return Promise.reject(error)
  }
)

const downloadFile = function(data, fileName) {
  if (!data) {
    return
  }
  console.log(data)
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  console.log(fileName)
  link.setAttribute('download', decodeURI(fileName, 'UTF-8'))

  document.body.appendChild(link)
  link.click()
}

const download = function(url, data) {
  let params = ''
  for (const key in data) {
    params += key + '=' + data[key] + '&'
  }
  return service({
    url: url + '?' + params,
    method: 'get',
    responseType: 'blob'
  })
}

const get = function(url, data) {
  let params = ''
  for (const key in data) {
    params += key + '=' + data[key] + '&'
  }
  if (!data) {
    return service({
      url: url,
      method: 'get'
    })
  } else {
    return service({
      url: url + '?' + params,
      method: 'get'
    })
  }
}

const post = function(url, data) {
  return service({
    url: url,
    method: 'post',
    data
  })
}
export { get, post, download }
export default service
