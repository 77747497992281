<template>
    <div>
        <div class="titlebox">设备类型分布占比</div>
        <div class="chartList">
            <pieChart v-if="list.length > 0" :list="list" />
            <img class="fg" src="../../assets/image/division@2x.png" alt="" srcset="">
            <div class="data">
                <div v-for="(item, index) in list" :key="index">
                    <div class="tb">
                        <span :style="{ background: color[index] }"></span>
                    </div>
                    <div class="name">{{ item.name }}</div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import pieChart from './pieChart.vue'
import { deviceratio } from '@/api/index.js'
export default {
    data() {
        return {
            list: [],
            color: ['#31A1F3','#F7AB20','#828893','rgba(0, 215, 233, 1)', 'rgba(139, 239, 198, 1)']
        }
    },
    components: {
        pieChart
    },
    created() {
        this.getdeviceratio()
    },
    methods: {

        getdeviceratio() {
            deviceratio({
                entId: '1598124821618962433'
            }).then(res => {
                this.list = res.slice(0, 5)
				console.log(res)
            })
        },
    }
}
</script>
<style scoped>
.titlebox {
    width: 480px;
    height: 54px;
    background: url('../../assets/image/titlebox@2x.png') top center no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-family: Alibaba PuHuiTi;
    font-weight: 500;
    color: #FFFFFF;
    padding-left: 20px;
    box-sizing: border-box;
}

.chartList {
    width: 480px;
    height: 160px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #1A2438;
}

.fg {
    width: 12px;
    height: 135px;
}

.data {
    width: 160px;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.tb {
    width: 16px;
    height: 16px;
    border: 1px solid #1F2C40;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.tb>span {
    display: inline-block;
    width: 6px;
    height: 6px;
}

.data>div {
    width: 100%;
    height: 30px;
    font-size: 16px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.name {
	width: 120px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 14px;
}
</style>
