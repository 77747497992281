<template>
  <div class="chart-container">
    <div class="chart" ref="chart"></div>
    <!-- 底座背景 -->
    <!-- <div class="bg"></div> -->
  </div>
</template>
 
<script>
import { getPie3D, getParametricEquation } from '@/utils/chart.js' //工具类js，页面路径自己修改

const color = ['rgba(0, 83, 255, 0.8)', 'rgba(255, 204, 38, 0.8)', 'rgba(89, 96, 110, 0.8)','rgba(0, 215, 233, 1)', 'rgba(139, 239, 198, 1)']

export default {
  name: 'chart',
  data() {
    return {
      optionData: [],
      statusChart: null,
      option: {}
    }
  },
  props: ['list'],
  watch: {
    list(val, oldVal) {
      
    }
  },
  mounted() {
    //根据窗口变化自动调节图表大小
    const that = this
    window.onresize = function () {
      that.changeSize()
    }
	this.setRegionalData()
  },
  methods: {
    setRegionalData() {
		console.log('=====', this.list)
      var res = []
      //{"cityCode":"140100","name":"太原市","num":55,"rate":55}
      this.list.map((item, index) => {
        res.push({
          "name": item.name,
          "num": item.num
        })
      })
      var total = 0
      var optionData = []
      if (res.length > 5) {
        res.forEach((item, index) => {
			
          if (index < 4) {
            total += Math.round(item.num)
            optionData.push({
              value: Math.round(item.num),
              name: item.name
            })
          }
        })
        optionData.push({
          value: 100 - total,
          name: '其他'
        })
      } else if (res.length < 5 || res.length == 5) {
        res.forEach(item => {
			
          optionData.push({
            value: Math.round(item.num),
            name: item.name
          })
        })
      }
	  console.log('.....',optionData)
	  
      this.setLabel(optionData)
    },

    // 初始化label样式
    setLabel(optionData) {
      optionData.forEach((item, index) => {
        item.itemStyle = {
          color: color[index]
        }
        item.label = {
          normal: {
            show: true,
            // color: color[index],
            formatter: [
              // '{b|{b}}',
              // '{c|{c}}{b|台}',
              '{c|{c}}'
            ].join('\n'), // 用\n来换行
            rich: {
              b: {
                color: '#fff',
                lineHeight: 25,
                align: 'left'
              },
              c: {
                fontSize: 12,
                color: '#fff',
                textShadowColor: '#1c90a6',
                textShadowOffsetX: 0,
                textShadowOffsetY: 2,
                textShadowBlur: 5
              },
              d: {
                // color: color[index],
                fontSize: 10,
                color: '#fff',
                align: 'left'
              }
            }
          }
        }
        // item.labelLine = {
        //   normal: {
        //     lineStyle: {
        //       width: 1,
        //       color: 'rgba(255,255,255,0.7)'
        //     }
        //   }
        // }
      })
      this.initChart(optionData)

    },
    // 图表初始化
    initChart(optionData) {
		
      var code = this.list.length > 1 ? 6 : 8
      this.statusChart = this.$echarts.init(this.$refs.chart)
      // 传入数据生成 option, 构建3d饼状图, 参数工具文件已经备注的很详细
      this.option = getPie3D(optionData, 0.8, 220, 28, code, 0.8)
      this.statusChart.setOption(this.option)
      this.option.legend = {
        x: 'center',
        y: 'bottom',
        show: false,
        orient: 'horizontal',
        textStyle: {
          fontSize: 10,
        },
        itemWidth: 4,
        itemHeight: 4,
        icon: 'rect',
        formatter: function (v) {
          // console.log('-----------v', v)
          // return v.data.name
          return v
        },
      }
      // 是否需要label指引线，如果要就添加一个透明的2d饼状图并调整角度使得labelLine和3d的饼状图对齐，并再次setOption
      this.option.series.push({
        name: '分布状态', //自己根据场景修改
        backgroundColor: 'transparent',
        type: 'pie',
        label: {
          opacity: 1,
          fontSize: 12,
          position: 'outside',
          padding: [0, 0, 0, 0],
        },
        labelLine: {
          show: true
        },
        startAngle: -40, // 起始角度，支持范围[0, 360]。
        clockwise: false, // 饼图的扇区是否是顺时针排布。上述这两项配置主要是为了对齐3d的样式
        radius: ['20%', '50%'],
        center: ['50%', '50%'],
        data: optionData,
        itemStyle: {
          opacity: 0  //这里必须是0，不然2d的图会覆盖在表面
        }
      })
      this.statusChart.setOption(this.option)
    },
    // 自适应宽高
    changeSize() {
      this.statusChart.resize()
    }
  }
}
</script>
 
<style scoped>
.chart-container {
  position: relative;
  width: 310px;
  height: 100%;
  /* border: 1px solid #f00; */
  display: inline-block;
  float: left;
  box-sizing: border-box;
}

.chart {
  width: 310px;
  height: 160px;
}

.bg {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  /* z-index: 1; */
  /* height: 73px; */
  /* background: #f00; */
  /* background: no-repeat center; */
  /* background-image: url('https://ks3-cn-beijing.ksyun.com/sxjg-elevator/datav-platform-2.0/images/chart_opacity_bg.png'); */
  /* background-size: 100% 100%; */
  /* transform: translateX(-50%); */
}
</style>