<template>
	<div class="home" :style="{'overflow' : fullscreen ? 'hidden' : '','height' : fullscreen ? '100%' : ''}">
		<div class="top">
			<div class="words"></div>
		</div>
		<div class="companyName">{{companyName}}</div>
		<div class="qpImg">
			<span style="margin-right: 40px;color: #fff;">{{ newDate }}</span>
			<img v-if="!fullscreen" @click="handleFullScreen()" src="../assets/image/qp.png" alt="" width="20px">
			<img v-else @click="handleFullScreen()" src="../assets/image/gbqp.png" alt="" width="20px"></img>
		</div>
		<div class="content">
			<div class="left">
				<equipment />
				<sensor />
				<jobList />
				<lData />
			</div>
			<div class="center">
				<centerH />
				<chinaMap />
			</div>
			<div class="right">
				<sensorList />
			</div>
		</div>
	</div>
</template>
<script>
import equipment from './components/equipment.vue'
import sensor from './components/sensor.vue'
import jobList from './components/jobList.vue'
import lData from './components/lData.vue'
import centerH from './components/centerH.vue'
import chinaMap from './components/chinaMap.vue'
import sensorList from './components/sensorList.vue'
import {
		information
	} from '@/api/index.js'
export default {
	data() {
		return {
			fullscreen: false,
			newDate: '',
			companyName: ''
		}
	},
	mounted() {
		this.getInformation()
		var _this = this
		setInterval(() => {
			_this.newDate = _this.time()
		}, 1000)
		
	},
	components: {
		equipment,
		sensor,
		jobList,
		lData,
		centerH,
		chinaMap,
		sensorList
	},
	methods: {
		getInformation() {
			information({
				entId: '1598124821618962433'
			}).then(res=> {
				this.companyName = res
			})
		},
		time() {
			var date = new Date();
			var Y = date.getFullYear() + '-';
			var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
			var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
			var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
			var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
			var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
			var arr = new Array("日", "一", "二", "三", "四", "五", "六");
			var week = new Date(date).getDay();
			var str = "星期" + arr[week];
			return Y + M + D + " " + str + " " + h + m + s;
		},
		handleFullScreen() {
			let element = document.documentElement;
			if (this.fullscreen) {
				if (document.exitFullscreen) {
					document.exitFullscreen();
				} else if (document.webkitCancelFullScreen) {
					document.webkitCancelFullScreen();
				} else if (document.mozCancelFullScreen) {
					document.mozCancelFullScreen();
				} else if (document.msExitFullscreen) {
					document.msExitFullscreen();
				}
			} else {
				if (element.requestFullscreen) {
					element.requestFullscreen();
				} else if (element.webkitRequestFullScreen) {
					element.webkitRequestFullScreen();
				} else if (element.mozRequestFullScreen) {
					element.mozRequestFullScreen();
				} else if (element.msRequestFullscreen) {
					// IE11
					element.msRequestFullscreen();
				}
			}
			this.fullscreen = !this.fullscreen;
		}
	}
}
</script>
<style scoped>
.home {
	position: relative;
	height: calc(100% - 100px);
}

.qpImg {
	position: absolute;
	right: 40px;
	top: 40px;
	z-index: 99;
	display: flex;
	align-items: center;
}

.companyName {
	position: absolute;
	left: 0;
	top: 20px;
	z-index: 99;
	font-size: 18px;
	font-family: Source Han Sans CN;
	font-weight: bold;
	color: #00D3FE;
	width: 373px;
	height: 35px;
	background: url('../assets/image/dpys@2x.png') top left no-repeat;
	background-size: 100% 100%;
	box-sizing: border-box;
	padding-left: 55px;
	padding-top: 0px;
	display: flex;
	align-items: center;
}

.top {
	width: 100%;
	height: 100px;
	background: url('../assets/image/TOP@2x.png') top center no-repeat;
	background-size: 100% 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.words {
	width: 344px;
	height: 27px;
	background: url('../assets/image/words@2x.png') top center no-repeat;
	background-size: 100% 100%;
	margin-bottom: 10px;
}

.content {
	padding: 20px 40px;
	position: relative;
	margin-top: 5px;
}

.left {
	position: relative;
	top: -60px;
}

.center {
	margin: 0 40px;
}

.right {
	width: 480px;
	display: flex;
	justify-content: flex-start;
	/* align-items: center; */
	flex-direction: column;
	position: relative;
	top: -60px;
}
</style>>