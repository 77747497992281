<template>
    <div class="page">
        <div class="titlebox">最新数据统计</div>
        <div class="tableList">
            <vue-seamless-scroll :data="list" class="warp" :class-option="seamlessScrollOption">
                <ul>
                    <li v-for="(item, index) in list" :key="index">
                        <div class="list">
                            <div class="title">
                                <div>标识：<span class="bs">{{ item.handle }}</span></div>
                                <div>时间：<span class="sj">{{ item.createTime }}</span></div>
                            </div>
                            <div class="content1">
                                <div v-for="(i, j) in item.dataList" :key="j">
                                    <div class="info">{{ i.value.toFixed(1) }}{{ i.unit }}</div>
                                    <div class="name">{{ i.name }}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </vue-seamless-scroll>
        </div>

    </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
import {queryNowData} from '@/api/index.js'
export default {
    data() {
        return {
            list: []
        }
    },
    components: { vueSeamlessScroll },
    computed: {
        seamlessScrollOption() {
            return {
                step: 0.2, // 数值越大速度滚动越快
                limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: false, // 是否开启鼠标悬停stop
                direction: 1, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
            };
        },
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            queryNowData({
                entId: '1598124821618962433'
            }).then(res=> {
                this.list = res
            })
        }
        
    }
}
</script>
<style scoped>
.page {
    margin-top: 20px;
}

.titlebox {
    width: 480px;
    height: 54px;
    background: url('../../assets/image/titlebox@2x.png') top center no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-family: Alibaba PuHuiTi;
    font-weight: 500;
    color: #FFFFFF;
    padding-left: 20px;
    box-sizing: border-box;
}

.title {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #45B3FC;
    display: flex;
    justify-content: space-between;
}

.bs,
.sj {
    color: #FFFFFF;
}

.tableList {
    padding: 10px 20px;
    border: 1px solid #1A2438;
    overflow: hidden;
    
}

.warp {
    height: 185px;
    overflow: hidden;
    box-sizing: border-box;
}

.info {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #A3C8FF;
    background: linear-gradient(0deg, #7BB0ED 0.146484375%, #FFFFFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.name {
    font-size: 13px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #A8B4C7;
}

.content1 {
    display: flex;
    justify-content: space-between;
}

.content1>div {
    width: 25%;
    background: #041635;
    margin: 5px 2px;
    padding: 5px 8px;
}

.list {
    padding: 10px;
}
</style>