<template>
	<div class="page">
		<div class="titlebox">数据展示</div>
		
		<div class="tableList">
		    <div class="title">
					<div>设备名称</div>
					<div>安装时间</div>
		        <div>状态</div>
		        
		    </div>
		    <div class="info1">
		        <vue-seamless-scroll :data="dList" class="warp" :class-option="seamlessScrollOption">
		            <ul>
		                <li v-for="(item, index) in dList" :key="index">
		                    <div class="list1">
													<div>{{ item.name }}</div>
													<div>{{ item.createTime }}</div>
		                        <div :style="{ color: item.status == 1 ? '#1AD193' : '#F60B63' }">{{
		                            item.status == 1 ? '正常' : '异常'
		                        }}</div>
		                        
		                    </div>
		                        
		                </li>
		            </ul>
		        </vue-seamless-scroll>
		
		    </div>
		</div>
		
		<div class="info">
			<div class="select">
				<el-select v-model="value" placeholder="请选择">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<template v-if="datas && datas.length">
				<div class="list">
					<div>
						<div class="box" v-for="(item, index) in datas" :key="index">
							<div class="name">{{ item.cgqName }}{{ item.value }}{{ item.unit }}</div>
							<div :id="'main' + index" style="width: 160px;height:200px;"></div>
						</div>
					</div>
					<div>
						<div class="box1" v-for="(item, index) in lineDatas.ylist" :key="index" :id="'main1' + index"
							style="width: 300px;height:180px;"></div>
					</div>
				</div>
			</template>
		</div>
	</div>

</template>
<script>
	import {
		dayData,
		currentData,
		noPage,
		deviceList
	} from '@/api/index.js'
import vueSeamlessScroll from 'vue-seamless-scroll'
	export default {
		data() {
			return {
				timer: null,
				options: [],
				value: '',
				datas: [],
				lineDatas: [],
				dList: []
			}
		},
		components: { vueSeamlessScroll },
		  computed: {
			seamlessScrollOption() {
				return {
					step: 0.2, // 数值越大速度滚动越快
					limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
					hoverStop: false, // 是否开启鼠标悬停stop
					direction: 1, // 0向下 1向上 2向左 3向右
					openWatch: true, // 开启数据实时监控刷新dom
					singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
					singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
					waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
				};
			},
		},
		beforeDestroy() {
			clearInterval(this.timer)
			this.timer = null
		},
		mounted() {
			this.getnoPage()
			this.getDeviceList()
			var that = this
			this.timer = setInterval(function(){
				that.init(that.value)
			},6000)
		},
		watch: {
			value: {
				// 数据发生变化就会调用这个函数  
				handler(newVal, oldVal) {
					this.init(this.value)
				},
				// 立即处理 进入页面就触发
				immediate: false
			}
		},
		methods: {
			getDeviceList() {
				deviceList({
					entId: '1598124821618962433'
				}).then(res=> {
					this.dList = res
				})
			},
			getnoPage() {
				noPage({
					entId: '1598124821618962433',
				}).then(res => {
					res.map((item, index) => {
						this.options.push({
							value: item.id,
							label: item.name
						})
					})
					this.value = this.options[0].value
					this.init(this.value)
				})
			},
			init(val) {
				currentData({
					cgqId: val
				}).then(res => {
					this.datas = res.slice(0, 3)
					
					this.$nextTick(() => {
					  this.drawChart()
					});
				})

				dayData({
					cgqId: val
				}).then(res => {
					this.lineDatas = res
					this.$nextTick(() => {
					  this.drawLineChart()
					});
					
				})
			},
			drawChart() {
				// 基于准备好的dom，初始化echarts实例
				var color = ['#00EAFF', '#FFA631', '#9841F7', '#00DED0', '#00EAFF', '#FFA631', '#9841F7', '#00DED0',
					'#00EAFF', '#FFA631', '#9841F7', '#00DED0'
				]
				for (var i = 0; i < this.datas.length; i++) {
					let eleName = 'main' + i
					var myChart = this.$echarts.init(document.getElementById(eleName));
					var option = {
						title: {
							show: false
						},
						series: [{
								type: "gauge",
								min: this.datas[i].min,
								max: this.datas[i].max,
								radius: "90%",
								center: ["50%", "50%"],
								axisLine: {
									show: true,
									lineStyle: {
										width: 5,
										shadowBlur: 5,
										roundCap: true,
										color: [
											[0.33, color[i]],
											[0.66, color[i]],
											[1, color[i]]
										]
									}
								},
								axisTick: {
									show: true,
									length: 10,
									lineStyle: {
										width: 2,
										color: '#3B445D'
									}
								},
								axisLabel: {
									show: true,
									textStyle: {
										fontSize: 10,
										color: "#3B445D"
									}
								},
								splitLine: {
									show: true,
									length: 10, //调刻度高度
									lineStyle: {
										width: 2,
										color: color[i]
									}
								},
								pointer: {
									show: true,
									width: "0",
									length: '50%'
								},
								detail: {
									show: false,
									formatter: '{value}%',
									offsetCenter: ['0', '50%'],
									textStyle: {
										fontSize: 14,
										color: "#3B445D"
									}
								},
								itemStyle: {
									color: "#27e4ae",
									borderColor: "#27e4ae",
									borderWidth: 5
								},
								data: [{
									value: this.datas[i].value,
									itemStyle: { // 数据项的指针样式。
										color: 'auto', // 图形的颜色。
										borderWidth: 2, // 描边线宽。为 0 时无描边。
										borderColor: color[i],
									}
								}]
							},
							{ //指针外环
								"type": 'pie',
								"hoverAnimation": false,
								"legendHoverLink": false,
								"radius": ['5%', '8%'],
								"z": 10,
								"label": {
									"normal": {
										"show": false
									}
								},
								"labelLine": {
									"normal": {
										"show": false
									}
								},
								"data": [{
									"value": 100,
									itemStyle: {
										normal: {
											color: "#67b3ef"
										}
									}
								}]
							},
							{ //指针内环
								"type": 'pie',
								"hoverAnimation": false,
								"legendHoverLink": false,
								"radius": ['0%', '5%'],
								"z": 10,
								"label": {
									"normal": {
										"show": false
									}
								},
								"labelLine": {
									"normal": {
										"show": false
									}
								},
								"data": [{
									"value": 100,
									itemStyle: {
										normal: {
											color: "#12214c"
										}
									}
								}]
							}
						]
					};
					myChart.setOption(option);
				}
			},
			drawLineChart() {
				var colorList = [
					['rgba(0, 213, 255, 1)', 'rgba(7,11,38,0)'],
					['rgba(251, 174, 67, 1)', 'rgba(251, 174, 67, 0)'],
					['rgba(170, 96, 249, 1)', 'rgba(169, 96, 249, 0)'],
					['rgba(71, 237, 213, 1)', 'rgba(58, 234, 212, 0)'],
					['rgba(0, 213, 255, 1)', 'rgba(7,11,38,0)'],
					['rgba(251, 174, 67, 1)', 'rgba(251, 174, 67, 0)'],
					['rgba(170, 96, 249, 1)', 'rgba(169, 96, 249, 0)'],
					['rgba(71, 237, 213, 1)', 'rgba(58, 234, 212, 0)'],
					['rgba(0, 213, 255, 1)', 'rgba(7,11,38,0)'],
					['rgba(251, 174, 67, 1)', 'rgba(251, 174, 67, 0)'],
					['rgba(170, 96, 249, 1)', 'rgba(169, 96, 249, 0)'],
					['rgba(71, 237, 213, 1)', 'rgba(58, 234, 212, 0)'],
				]
				for (var i = 0; i < this.lineDatas.ylist.length; i++) {
					let max = Math.max(...this.lineDatas.ylist[i].list); //计算最大值
					let min = Math.min(...this.lineDatas.ylist[i].list)
					let unit = this.lineDatas.unitList[i]
					let eleName = 'main1' + i
					var myChart = this.$echarts.init(document.getElementById(eleName));
					var option = {
						title: {
							// text: this.lineDatas.ylist[i].name,
							// left: 'center',
							// top: 0
						},
						grid: { //图表位置
							left: '3%',
							right: '8%',
							bottom: '10%',
							top: '10%',
							containLabel: true
						},
						tooltip: { // 本系列特定的 tooltip 设定。   
							show: true,
							formatter: "{b}：{c}%",
							backgroundColor: "rgba(50,50,50,0.7)", // 提示框浮层的背景颜色。注意：series.tooltip 仅在 tooltip.trigger 为 'item' 时有效。
							borderColor: "#333", // 提示框浮层的边框颜色。...
							borderWidth: 0, // 提示框浮层的边框宽。...
							padding: 5, // 提示框浮层内边距，单位px，默认各方向内边距为5，接受数组分别设定上右下左边距。...
							textStyle: { // 提示框浮层的文本样式。...
								// color ,fontStyle ,fontWeight ,fontFamily ,fontSize ,lineHeight ,.......
							},
						},
						xAxis: {
							boundaryGap: false,
							type: 'category',
							data: this.lineDatas.xlist,
							axisLabel: {
								textStyle: {
									color: "#DEEBFF", //坐标的字体颜色
								},
								showMaxLabel: true,
							},
						},
						yAxis: {
							type: 'value',
							axisLabel: {
								textStyle: {
									color: "#48526E", //坐标的字体颜色
								},
								// formatter: function(val) {
								//     return val + unit
								// },
							},

							min: min,
							splitLine: {
								//网格线颜色
								lineStyle: {
									color: ["#101C35"],
									width: 1,
									type: "solid",
								},
							},
							axisLine: {
								//坐标轴线颜色
								lineStyle: {
									color: "#2D3C5C",
								},
							},
						},
						series: [{
							data: this.lineDatas.ylist[i].list,
							type: 'line',
							symbol: function(value, params) {
								if (value == max) {
									return 'circle'; //拐点类型
								} else {
									return 'none'; //拐点不显示
								}
							},
							smooth: true,
							// symbolSize: 'circle',
							color: colorList[i][0],
							markPoint: {
								symbolSize: 20,
								itemStyle: {
									normal: {
										color: 'rgba(0,0,0,0)'
									}
								},
								label: {
									show: true,
									color: '#fff'
								},
								data: [{
									type: 'max',
									name: 'Max'
								}]
							},
							areaStyle: {
								//渐变色
								color: {
									type: "linear",
									x: 0,
									y: 0,
									x2: 0,
									y2: 1,
									colorStops: [{
											offset: 0,
											color: colorList[i][0], // 0% 处的颜色
										},
										{
											offset: 1,
											color: colorList[i][1], // 100% 处的颜色
										}
									],
								},
							},
						}]
					};
					myChart.setOption(option);
				}

			}
		}
	}
</script>
<style scoped>
	.page {
		padding: 10px;
	}

	.titlebox {
		width: 480px;
		height: 54px;
		background: url('../../assets/image/titlebox@2x.png') top center no-repeat;
		background-size: 100% 100%;
		display: flex;
		align-items: center;
		font-size: 20px;
		font-family: Alibaba PuHuiTi;
		font-weight: 500;
		color: #FFFFFF;
		padding-left: 20px;
		box-sizing: border-box;
	}

	.info {
		border: 1px solid #051025;
		box-sizing: border-box;
	}

	.select {
		width: 100%;
		text-align: center;
		margin: 30px 0 10px 0;
	}

	.el-select {
		width: 80%;
	}

	.list {
		width: 100%;
		display: flex;
		justify-content: center;
		border-top: 1px solid #051025;
		padding: 12px 10px;
		box-sizing: border-box;
		height: calc(100vh - 320px);
		overflow-y: auto;
	}

	/deep/ .el-input__inner {
		background: #051025;
	}

	/deep/ .el-input__inner {
		border: 1px solid #051025 !important;
		font-size: 16px;
		font-family: Alibaba PuHuiTi;
		font-weight: 500;
		color: #BAC5D6;
	}

	.box {
		width: 160px;
		height: 200px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		margin: 20px 0;
	}
	
	.box1 {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		margin: 30px 0;
	}

	.name {
		font-size: 16px;
		font-family: Alibaba PuHuiTi;
		font-weight: 500;
		color: #FFFFFF;

		background: linear-gradient(0deg, #FFFFFF 0.146484375%, #7BB0ED 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	
	
	.tableList {
	    padding: 20px;
	    box-sizing: border-box;
	    border: 1px solid #1A2438;
			margin-top: 20px;
	}
	
	.info1 {
	    height: 100px;
	    overflow: hidden;
	}
	
	.title {
	    width: 100%;
	    display: flex;
	    justify-content: flex-start;
	    font-size: 14px;
	    font-family: Source Han Sans CN;
	    font-weight: 500;
	    color: #A3C8FF;
	}
	
	.title>div {
	    margin: 4px 2px;
	    padding: 2px 5px;
	}
	
	.title>div:nth-child(1),
	.list1>div:nth-child(1) {
	    width: 40%;
	}
	
	.title>div:nth-child(2),
	.list1>div:nth-child(2) {
	    width: 40%;
	}
	
	.title>div:nth-child(3),
	.list1>div:nth-child(3) {
	    width: 20%;
	}
	
	.list1 {
	    width: 100%;
	    display: flex;
	    justify-content: flex-start;
	    font-size: 14px;
	    font-family: Source Han Sans CN;
	    font-weight: 400;
	    color: #FFFFFF;
	}
	
	.list1>div {
	    background: #041635;
	    margin: 2px;
	    padding: 5px;
	}
</style>