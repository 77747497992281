<template>
    <div class="centerH">
        <div class="leftC">
            <div>
                <div class="title1">
                    <img class="zc" src="../../assets/image/zc@2x.png" alt="">
                    <span>标识注册量</span>
                </div>
                <div class="num">
                    <countTo ref='example1' :start-val='startVal' :end-val='Number(statisticsnum.regNum)'
                        :duration='1000'>
                    </countTo>
                </div>
            </div>
            <div>
                <div class="title1">
                    <img class="jx" src="../../assets/image/jx@2x.png" alt="">
                    <span>标识解析量</span>
                </div>
                <div class="num">
                    <countTo ref='example2' :start-val='startVal' :end-val='Number(statisticsnum.queryNum)'
                        :duration='1000'>
                    </countTo>
                </div>
            </div>
        </div>
        <div class="rightC">
            <div>
                <div>
                    <span class="tname">设备数量</span>
                    <span class="code">
                        <countTo ref='example3' :start-val='startVal' :end-val='Number(devicenum)' :duration='1000'>
                        </countTo>
                    </span>
                </div>
                <div>
                    <span class="tname">设备类型</span>
                    <span class="code">
                        <countTo ref='example4' :start-val='startVal' :end-val='Number(deviceTypenum)' :duration='1000'>
                        </countTo>
                    </span>
                </div>
            </div>
            <div>
                <div>
                    <span class="tname">传感器数量</span>
                    <span class="code">
                        <countTo ref='example5' :start-val='startVal' :end-val='Number(cgqnum)' :duration='1000'>
                        </countTo>
                    </span>
                </div>
                <div>
                    <span class="tname">传感器类型</span>
                    <span class="code">
                        <countTo ref='example6' :start-val='startVal' :end-val='Number(cgqTypenum)' :duration='1000'>
                        </countTo>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import countTo from 'vue-count-to'
import { cgqCount, cgqType, deviceCount, deviceType, statistics } from '@/api/index.js'
export default {
    data() {
        return {
            startVal: 0,
            cgqnum: 0,
            cgqTypenum: 0,
            devicenum: 0,
            deviceTypenum: 0,
            statisticsnum: {
                queryNum: 0,
                regNum: 0
            }
        }
    },
    mounted() {
        const t = window.setInterval(() => {
            this.$refs.example1.start();
            this.$refs.example2.start();
            this.$refs.example3.start();
            this.$refs.example4.start();
            this.$refs.example5.start();
            this.$refs.example6.start();
        }, 5000)
        this.$once('hook:beforeDestroy', () => {
            clearInterval(t)
        })
    },
    components: {
        countTo
    },
    mounted() {
        this.getcgqCount()
        this.getcgqType()
        this.getdeviceCount()
        this.getdeviceType()
        this.getstatistics()
    },
    methods: {
        getcgqCount() {
            cgqCount({
                entId: '1598124821618962433'
            }).then(res => {
                this.cgqnum = res
            })
        },
        getcgqType() {
            cgqType({
                entId: '1598124821618962433'
            }).then(res => {
                this.cgqTypenum = res
            })
        },
        getdeviceCount() {
            deviceCount({
                entId: '1598124821618962433'
            }).then(res => {
                this.devicenum = res
            })
        },
        getdeviceType() {
            deviceType({
                entId: '1598124821618962433'
            }).then(res => {
                this.deviceTypenum = res
            })
        },
        getstatistics() {
            statistics({
                entId: '1598124821618962433'
            }).then(res => {
                this.statisticsnum = res
            })
        }
    }
}
</script>
<style scoped>
.centerH {
    width: 770px;
    height: 140px;
    background: url('../../assets/image/header@2x.png') top center no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.leftC {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.leftC>div {
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.title1 {
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
    height: 30px;
}

.title1>span {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #CED5F8;
    margin-left: 10px;
}

.zc {
    width: 18px;
    height: 20px;
}

.jx {
    width: 20px;
    height: 20px;
}

.num {
    font-size: 28px;
    font-family: Arial;
    font-weight: bold;
    color: #93F1FC;
    background: linear-gradient(0deg, #FFFFFF 0.146484375%, #7BB0ED 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.rightC {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.rightC>div {
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.rightC>div>div {
    margin: 2px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tname {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #CED5F8;
    margin-right: 10px;
}

.code {
    font-size: 26px;
    font-family: Arial;
    font-weight: bold;
    color: #93F1FC;

    background: linear-gradient(0deg, #FFFFFF 0.146484375%, #7BB0ED 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
</style>
